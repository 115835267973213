import {
  useAuthContext,
  useUserAdditionalData,
} from '@indomita-react/auth-provider';
import type { TabItemType } from '@indomita-react/user-tab';
import { UserTab } from '@indomita-react/user-tab';

import { useUserConfigLink } from 'src/hooks/useUserConfigLink';

export function UserTabWrapper({
  tabSectionActive,
}: {
  tabSectionActive?: TabItemType;
}) {
  const { linkConfig, mobileConfig, onLoginClick, userTabLinksConfig } =
    useUserConfigLink();
  const { user } = useAuthContext();
  const { counters } = useUserAdditionalData();

  return (
    <UserTab
      tabLinkConfig={userTabLinksConfig}
      tabSectionActive={tabSectionActive}
      linkConfig={linkConfig}
      mobileConfig={mobileConfig}
      onLoginClick={onLoginClick}
      onRegisterClick={onLoginClick}
      mailVerified={Boolean(user?.mailVerified)}
      name={user?.name}
      phoneVerified={Boolean(user?.phoneVerified)}
      thumb={user?.thumb ?? undefined}
      surname={user?.surname}
      uuid={user?.uuid}
      counters={counters}
    />
  );
}
